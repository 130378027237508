.App-main-right-examples {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 0.2rem;
    padding-left: 0.5rem;
}
  
.App-main-right-examples-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #eeeeeecf;
    margin-bottom: 1rem;
}
  
.App-main-right-examples-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: .8rem;
    margin-top: .2rem;
    margin-bottom: 1rem;
}
  
.feather-chevron-right {
    width: 1.4rem;
    height: 1.4rem;
    stroke-width: 2.3;
    color: #eee;
}

.examples-button {
    background: #4862a3e6;
    color: #ddd;
    font-family: inherit;
    padding: 0.3em;
    padding-left: 1.2em;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: 0em 0em 2em #7497F324, 0em 0.4em 0.6em #000000c0;
    overflow: hidden;
    position: relative;
    height: 2.8em;
    width: 30em;
    padding-right: 3.3em;
    transition: all .1s ease-in-out;
    animation: fadeInUp 0.5s ease-in-out;
}

.examples-button:nth-child(1) {
    animation-delay: 0.1s;
}

.examples-button:nth-child(2) {
    animation-delay: 0.2s;
}

.examples-button:nth-child(3) {
    animation-delay: 0.3s;
}

.examples-button:nth-child(4) {
    animation-delay: 0.4s;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.examples-button:hover {
    box-shadow: 0 0 4em #7497F354, 0em 0.4em 0.6em #000000f0;
    transform: translateY(-0.2em);
}
  
.examples-button .icon {
    background: #333;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #7497F364;
    right: 0.3em;
    transition: all .5s;
}
  
.examples-button:hover .icon {
    width: calc(100% - 0.6em);
}
  
.examples-button .icon svg {
    width: 1.1em;
    transition: transform .5s;
    color: #ddd;
}
  
.examples-button:hover .icon svg {
    transform: translateX(0.1em);
}
  
.examples-button:active .icon {
    transform: scale(0.95);
}

.icon-btn {
    width: 40px;
    height: 40px;
    border: none;
    background: #51ab60;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    transition: width 0.2s ease-in-out;
    font-weight: 500;
    font-family: inherit;
    box-shadow: 0em 0em 1em #51ab6064, 0em 0.4em 0.6em #000000fa;
    animation: fadeInUp 0.5s ease-out both;
    animation-delay: 0.5s;
}

.icon-btn:hover {
    background-color: #51ab60ef;
}

.add-btn:hover {
    width: 120px;
}
  
.add-btn::before,
.add-btn::after {
    transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 3px;
    width: 16px;
    top: calc(50% - 1px);
    background: white;  
}
  
.add-btn::after {
    right: 15px;
    overflow: hidden;
}
  
.add-btn::before {
    left: 15px;
}
  
.icon-btn:focus {
    outline: none;
}
  
.btn-txt {
    color: #ddd;
    font-size: 1.1rem;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.2s;
}
  
.add-btn:hover::before,
.add-btn:hover::after {
    width: 5%;
    background: transparent;
}
  
.add-btn:hover .btn-txt {
    opacity: 1;
}
  
.add-icon::after,
.add-icon::before {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 20px;
    width: 3px;
    top: calc(50% - 9px);
    background: white;
    overflow: hidden;
}
  
.add-icon::before {
    left: 18px;
    background: transparent;
}
  
.add-icon::after {
    right: 18px;
}
  
.add-btn:hover .add-icon::before {
    left: 27px;
    height: 4px;
    top: calc(50% - 2px);
}
  
.add-btn:hover .add-icon::after {
    right: 22px;
    height: 4px;
    top: calc(50% - 2px);
    background: transparent;
}

@media (prefers-color-scheme: light) {
    .examples-button {
        background: #5c77c1;
        color: #fff;
        box-shadow: 0em 1em 3em #7497F334, 0em 0.4em 0.6em #00000030;
    }

    .examples-button:hover {
        box-shadow: 0em 1em 3em #7497F364, 0em 0.4em 0.6em #00000060;
    }

    .icon-btn {
        background: #60bb70;
        box-shadow: 0em 1em 3em #7497F334, 0em 0.4em 0.6em #00000070;
    }

    .icon-btn:hover {
        background-color: #60bb70ef;
    }

    .btn-txt {
        color: #fff;
    }

    .App-main-right-examples-title {
        color: #333;
    }
}

@media (max-width: 768px) {
    .App-main-right-examples {
        flex-direction: column;
        align-items: center;
        width: 105%;
        padding-left: 0rem;
    }

    .App-main-right-examples-buttons {
        width: 100%;
        gap: 0em;
        margin-bottom: 0.1rem;
    }

    .examples-button {
        width: 100%;
        margin-bottom: 1em;
        font-size: .9em;
        padding: 1.2em 1.5em;
    }
}