.big-modal {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    height: 60vh;
    max-width: 55vw;
    max-height: 60vh;
    background-color: #151515;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 0 20px 30px rgba(0,0,0,0.2);
}

.big-modal.sidebar-open {
    left: 58%;
}

.big-modal-inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.big-modal-left {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    padding: .5rem .2rem;
    overflow: hidden;
}

.big-modal-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 95%;
    padding: 1rem;
}

.big-modal-title {
    color: #ffffff9f;
    font-size: 1.3rem;
    font-weight: 500;
    width: 90%;
    margin: 0rem;
    padding: .75rem .5rem;
    border-radius: 15px;
    transition: background-color 0.3s ease;
    border-right: 3px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
}

.big-modal-title.active {
    background-color: #353535;
    font-weight: 500;
}

.big-modal-title:hover {
    cursor: pointer;
}

.big-modal-left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.big-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
}

.big-modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* border-bottom: .5px solid #ffffff3f; */
    padding: 0rem 1rem;
}

.big-modal-header-title {
    color: #ffffff8f;
    font-size: 1.8rem;
    font-weight: 600;
    width: 100%;
    margin: 0rem;
    padding: .9rem 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .75rem;
}

.big-modal-title .feather {
    width: 1.3rem;
    height: 1.3rem;
    color: #ffffff6f;
}

.big-modal-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    width: 100%;
    padding: 1rem 0rem;
}

.big-modal-body-text {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: #aaa;
    padding: 0;
    margin: 0.5rem;
    cursor: pointer;
}

.big-field-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: .5rem;
    width: 100%;
}

.small-modal {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35vw;
    background-color: #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1000;
}

.small-modal.sidebar-open {
    left: 58%;
}

.error-modal {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1000;
}

.error-modal.sidebar-open {
    left: 58%;
}

.error-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}

.error-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    padding-bottom: .1rem;
    gap: 0rem;
}

.error-modal-body-text {
    text-align: center;
    max-width: 75%;
    font-size: 1.3rem;
    font-weight: 500;
    color: #aaa;
    padding: 0;
    margin: 0.5rem;
    cursor: pointer;
}

.error-image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #FEE2E2;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 9999px;

    margin-top: 1rem;
    margin-bottom: 1rem;
}

.error-image svg {
    color: #DC2626;
    width: 3.5rem;
    height: 3.5rem;
}

.small-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.auth-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%; 
    padding: 1rem;
    gap: 1rem;
}

.google-btn {
    border: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: .7rem 1.5rem;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #444;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .4), 0 3px 3px rgba(0, 0, 0, .5);
}

.google-btn:hover {
    background-color: #eee;
    cursor: pointer;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.8), 0 2px 4px rgba(0, 0, 0, 0.5);
}

.google-logo {
    width: 1.5em
}

.small-modal-splitter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem 0;
}

.small-modal-splitter-text {
    color: #999;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0 1rem;
}

.small-modal-splitter-line {
    width: 40%;
    height: .05em;
    background-color: #888;
}

.google-signin-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}
.google-signin-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.google-signin-btn:active {
    background-color: #eeeeee;
}
.google-signin-btn:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}
.google-signin-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.small-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0rem;
    margin: -2rem;
}

.small-modal-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%;  */
    padding: 1rem;
    gap: 1rem;
    /* background-color: #232323; */
    border-radius: 20px;
}

.small-modal-title {
    font-size: 1.9rem;
    font-weight: 700;
    padding: 0;
    color: #eee;
}

.small-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    padding-bottom: 1rem;
    gap: 1rem;
}

.small-modal-body-text {
    font-size: 1.3rem;
    font-weight: 500;
    color: #ccc;
    padding: 0;
    margin: 0.5rem;
    cursor: pointer;
}

.small-close-button {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .2rem .2rem;
    background-color: transparent;
    color: #FF5E5B;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 7px;
    transition: 0.3s ease;
    z-index: 100;
}

.big-close-button {
    --shadow: 0 5px 15px 0 #00000026;
    --shadow-active: 0 5px 5px 0 #00000026;
    --border-radius-main: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 1rem;
    height: 1rem;
    color: transparent;
    font-size: 0;
    cursor: pointer;
    background-color: #ff5f5f80;
    border: none;
    border-radius: var(--border-radius-main);
    -webkit-border-radius: var(--border-radius-main);
    -moz-border-radius: var(--border-radius-main);
    -ms-border-radius: var(--border-radius-main);
    -o-border-radius: var(--border-radius-main);
    transition: .2s ease all;
    -webkit-transition: .2s ease all;
    -moz-transition: .2s ease all;
    -ms-transition: .2s ease all;
    -o-transition: .2s ease all;
    z-index: 1002;
}

.big-close-button:hover {
    width: 1.2rem;
    height: 1.2rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #ff5f5fcc;
    box-shadow: var(--shadow-active);
    -moz-box-shadow: var(--shadow-active);
    -webkit-box-shadow: var(--shadow-active);
}

.big-close-button:active {
    width: 1.1rem;
    height: 1.1rem;
    font-size: .9rem;
    color: #ffffffde;
    --shadow-btn-close: 0 3px 3px 0 #00000026;
    box-shadow: var(--shadow-btn-close);
    -moz-box-shadow: var(--shadow-btn-close);
    -webkit-box-shadow: var(--shadow-btn-close);
}

.feather-x {
    width: 2rem;
    height: 2rem;
    stroke-width: 2.5px;
    color: #FF5E5B7f;
}

.feather-x:hover {
    color: #FF5E5Baf;
}

.small-close-button .feather-x {
    width: 1.7rem;
    height: 1.7rem;
    stroke-width: 2.6px;
    color: #FF5E5Baf;
    transition: 0.3s ease-in-out;
}

.small-close-button .feather-x:hover {
    color: #FF5E5B;
}

.small-close-button-text {
    margin-bottom: 2px;
    margin-left: 2px;
}

.small-close-button:hover {

    cursor: pointer;
}

.bold {
    font-weight: 700;
    color: #eee;
}

.small-modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    height: 100%;
    padding: 1rem;
    gap: 1rem;
}

.input-field-container {
    position: relative;
}

.input-field-container input {
    padding: .65rem;
    width: 28rem;
    font-size: 1.45rem;
    border: 1px solid #666;
    border-radius: 12px;
    background-color: transparent;
    color: #eee;
}

.input-field-container input:focus {
    outline: none;
    border: 1px solid #aaa;
    border-radius: 8px;
}

.input-field-container label {
    font-size: 1.3rem;
    color: #777;
    position: absolute;
    left: 0;
    padding: 0.6em;
    align-items: flex-start;
    pointer-events: none;
    text-align: left;
    transition: all 0.3s ease;
    transform-origin: left;
}

.input-fields input:focus {
    outline: none;
    border: 1px solid #aaa;
    border-radius: 8px;
}

.input-field-container input:focus + label,
.input-field-container input.non-empty + label {
    transform: translateY(-50%) scale(.8);
    margin: 0em;
    margin-left: .5em;
    padding: 0.1em;
    background-color: transparent;
    backdrop-filter: blur(4px);
    color: #ddd;
}

.small-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
    gap: 2rem;
    padding-bottom: 1rem;
}

.small-modal-footer-text {
    font-size: 1.1rem;
    color: #ccc;
    padding: 0;
    margin: 0.5rem;
    cursor: pointer;
}

.small-modal-footer-text:hover {
    color: #eee;
}

.small-modal-button {
    padding: .75rem 2.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    border: 1px solid #666;
    background-color: #6985CA;
    color: #eee;
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-radius: .5em;
    transition: all .2s;
    border: none;
}

.small-modal-button:hover {
    filter: brightness(1.1);
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
/*    
.small-modal-button:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
   
.small-modal-button::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}
   
.small-modal-button::after {
    background-color: #6985CA5f;
}
   
.small-modal-button:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.small-modal-button:active {
    transform: translateY(0px);
    box-shadow: 0px 0px 0px 0px #ffffff90;
} */

.small-modal-button-close {
    padding: .65rem 2.2rem;
    font-size: 1.25rem;
    font-weight: 700;
    border: 1px solid #666;
    border-radius: 12px;
    background-color: transparent;
    background: #83898a;
    cursor: pointer;
}

.small-modal-button-close:hover {
    background: #c3c9ca;
    box-shadow: 0px 2px 0px 0px #ffffff90;
}

.redwhitehover:hover {
    background-color: #FF5E5B;
    color: #fff;
}

.small-modal .green {
    background-color: #6EEB83;
    color: #222;
}

.blue {
    background-color: #6985CA;
    color: #fff;
}

@media screen and (max-width: 1024px) {
    .small-modal {
        width: 50vw;
    }

    .input-field-container input {
        width: 40vw;
    }
}

@media screen and (max-width: 1440px) {
    .small-modal {
        width: 50vw;
        left: 50%;
    }

    .input-field-container input {
        width: 30vw;
    }
}

@media screen and (max-width: 768px) {
    .small-modal {
        width: 90vw;
    }

    .small-modal.sidebar-open {
        left: 50%;
    }

    .error-modal {
        width: 90vw;
    }

    .error-modal.sidebar-open {
        left: 50%;
    }

    .big-modal {
        width: 100vw;
        max-width: 90vh;
    }

    .big-modal-inner {
        width: 80vw;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 3rem;
        /* right: 0; */
    }

    .big-modal-left {
        width: 100vw;
    }

    .big-modal-right {
        width: 80vw;
    }

    .big-modal-content {
        width: 90vw;
    }

    .big-modal.sidebar-open {
        left: 50%;
    }

    .input-field-container input {
        width: 80vw;
    }

    .small-modal-button {
        padding: .75rem 1.5rem;
        font-size: 1.25rem;
    }

    .small-modal-button-close {
        padding: .65rem 1.5rem;
        font-size: 1.25rem;
    }

    .big-close-button {
        width: 24px;
        height: 24px;
    }

    .small-close-button {
        top: 0.3rem;
        right: 0.3rem;
    }
}

@media (prefers-color-scheme: light) {
    .big-modal {
        background-color: #cfcfcf;
        color: #222;
    }

    .big-modal-left {
        color: #222;
    }

    .big-modal-right {
        color: #222;
    }

    .big-modal-header {
        border-color: #222;
    }

    .big-modal-header-title {
        color: #222;
    }

    .big-modal-title {
        color: #222;
    }

    .big-modal-title.active {
        color: #222;
        background-color: #aaa;
    }

    .big-modal-title .feather {
        color: #222;
    }

    .big-modal-body-text {
        color: #0000007f;
    }

    .small-modal-body-text {
        color: #0000007f;
    }

    .feather-x {
        color: #222;
    }

    .small-modal {
        background-color: #424242;
        color: #222;
    }

    .small-modal-header {
        background-color: #424242;
        color: #222;
    }

    .small-modal-footer {
        background-color: #424242;
        color: #222;
    }

    .small-modal-button {
        background-color: #6985CA;
        color: #fff;
    }

    .small-modal-button:hover {
        filter: brightness(1.1);
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
       
    .small-modal-button:active {
        transform: translateY(-1px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }
       
    .small-modal-button::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 0.5em;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .2s;
    }
       
    .small-modal-button::after {
        background-color: #6985CA5f;
    }
       
    .small-modal-button:hover::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
    }

    .small-modal-button:active {
        transform: translateY(0px);
        box-shadow: 0px 0px 0px 0px #ffffff90;
    }

    .small-modal-button-close {
        background-color: #83898a;
        color: #fff;
    }

    .small-modal-button-close:hover {
        background: #c3c9ca;
        box-shadow: 0px 2px 0px 0px #ffffff90;
    }

    .small-modal .green {
        background-color: #6EEB83;
        color: #222;
    }

    .big-close-button {
        background-color: #FF5E5B;
        color: #fff;
    }

    .blue {
        background-color: #6985CA;
        color: #fff;
    }

    .redwhitehover:hover {
        background-color: #FF5E5B;
        color: #fff;
    }
}