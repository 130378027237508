.code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* gap: 0.2rem; */
    /* overflow-y: auto; */
}

.loader-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a2a2a91;
    box-shadow: 0 10px 10px rgba(0,0,0,0.2);
    border-radius: 1rem;
    padding: 1em .75em;
    padding-top: 1.5em;
    padding-bottom: 2em;
    width: 102.5%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    animation: fadeInUp 0.5s ease-out both;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.loader-title {
    font-size: 1.3rem;
    font-weight: 700;
    color: #eeeeeecf;
    margin-bottom: .3rem;
}

.loader-title-small {
    font-size: 1rem;
    font-weight: 700;
    color: #eeeeeeaf;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .loader-outer-container {
        width: 90%;
    }
}

@media (prefers-color-scheme: light) {
    .loader-outer-container {
        background-color: #313131;
        box-shadow: 0 3px 5px #0000005f;
    }

    .loader-title {
        color: #fff;
    }
}