.workspace-main {
    display: flex;
    flex-direction: column;
    width: 16%;
    height: 100vh;
    position: fixed; 
    overflow-y: hidden;
}

@media (max-width: 2000px) {
    .workspace-main {
      width: 14%;
    }
}
  
@media (max-width: 1800px) {
    .workspace-main {
      width: 15%;
    }
}
  
@media (max-width: 1600px) {
    .workspace-main {
      width: 16%;
    }
}
  
@media (max-width: 1400px) {
    .workspace-main {
      width: 18%;
    }
}
  
@media (max-width: 1200px) {
    .workspace-main {
      width: 20%;
    }
}

.App-main-left-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.1rem;
    padding-left: 0.5rem;
    height: 7%;
    gap: 0.5rem;
}

.App-main-left-header-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #eee;
}

.App-main-left-header-button {
    font-size: 2.4rem;
    font-weight: 400;
    color: #eee;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
  
.App-main-left-header-button:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
}

.App-main-left-header-close {
    font-size: 2.4rem;
    font-weight: 400;
    color: #eee;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.workspaces {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80%;
    gap: .5rem;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.workspaces-divider {
    width: 95%;
    height: 1px;
    background-color: #555;
    margin: 0.5rem 0rem;
}

.workspaces-big-divider {
    width: 95%;
    height: 150px;
    /* background-color: #555; */
    margin: 1.5rem 0rem;
}

.workspaces::-webkit-scrollbar {
    display: none;
}

.workspaces-workspace-placeholder {
    height: 50rem;
    width: 95%;
    background-color: #222;
}
  
.workspaces-workspace {
    opacity: 0.85;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 95%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.7rem;
    position: relative;
    background-color: #333;
    padding: 0.8rem 0rem;
    border-radius: 0.5rem;
}

.workspaces-workspace.active {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: scale(1.01);
    background-color: #444;
    padding: 0.8rem 0rem;
    border-radius: 0.5rem;
}
  
.workspaces-workspace:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    /* transform: scale(1.01); */
}

.workspaces-workspace-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
    overflow: hidden;
}

.workspaces-workspace-inner-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0rem;
    overflow: hidden;
}
  
.workspaces .feather {
    height: 1.2rem;
    stroke-width: 2;
    color: #ddd;
    opacity: 0.8;
    padding-left: 0.5rem;
}

.workspaces .feather:hover {
    opacity: .9;
    transition: all 0.3s ease-in-out;
    transform: scale(1.01);
}

.feather-edit-2 {
    padding-right: 0.5rem;
}

.workspaces-workspace-name {
    font-size: 1.1rem;
    font-weight: 500;
    color: #ddd;
    white-space: nowrap;
}

.workspaces-workspace-name:hover {
    white-space: normal;
}

.workspaces-workspace-name input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #ddd;
    font-size: 1.1rem;
    font-weight: 500;
    color: #ddd;
    width: 80%;
}

.workspaces-workspace-name input:focus {
    outline: none;
    border-bottom: 1px solid #ddd;
}
  
.workspaces-divider {
    width: 100%;
    height: 0.1rem;
    background-color: #999;
    opacity: 0.15;
}

.App-main-left-bottom {
    position: absolute;
    bottom: 3.9rem;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    width: 100%;
}
  
.App-main-left-bottom-button {
    font-size: 1.4rem;
    font-weight: 500;
    color: #ddd;
    opacity: 0.75;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 100%;
    border-radius: 2.5rem;
    padding: .75rem 0rem;
    text-align: left;
    align-items: center;
    display: flex;
    margin-left: .5rem;
}
  
.App-main-left-bottom-button .feather {
    width: 2rem;
    height: 1.7rem;
    stroke-width: 2.3;
    color: #eee;
    opacity: 0.75;
    margin-right: .5rem;
}
  
.App-main-left-bottom-button:hover {
    opacity: .9;
    transition: all 0.3s ease-in-out;
}

.App-main-left-footer {
    position: absolute;
    bottom: 0rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding-top: 2.5rem;
    /* margin-left: 0.5rem; */
    /* background-color: #; */
}
  
.App-main-left-footer-text {
    font-size: 1.1rem;
    font-weight: 700;
    padding: .4rem .6rem;
    margin-left: 0.3em;
    color: #eee;
    background-color:  #6584d5; /* #5D7AC5; */
    box-shadow: 0.1em 0.1em 0.6em 0.2em #7497F344;
    opacity: 0.9;
    border-radius: 3rem;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1280px) {
    .workspace-main {
        width: 100%;
        position: static; 
    }

    .App-main-left-header {
        padding: 0.5rem;
    }

    .App-main-left-header-title {
        font-size: 1.2rem;
    }

    .App-main-left-header-button,
    .App-main-left-header-close {
        font-size: 2rem;
    }

    .workspaces {
        max-height: 60%;
    }

    .workspaces-workspace-placeholder {
        height: 30rem;
    }

    .workspaces-workspace {
        padding: 0.6rem 0rem;
    }

    .workspaces-workspace.active {
        padding: 0.6rem 0rem;
    }

    .workspaces-workspace-name {
        font-size: 1rem;
    }

    .workspaces-workspace-name input {
        font-size: 1rem;
    }

    .App-main-left-bottom-button {
        font-size: 1.4rem;
        padding: .75rem .2rem;
    }

    .App-main-left-bottom-button .feather {
        width: 1.8rem;
        height: 1.8rem;
    }

    .App-main-left-footer-text {
        font-size: 1.1rem;
        padding: .3rem .5rem;
    }
}

@media (prefers-color-scheme: light) {
    .App-main-left-header {
        background-color: #313131;
    }

    .App-main-left-header-title {
        color: #fff;
    }

    .App-main-left-header-button,
    .App-main-left-header-close {
        color: #fff;
    }

    .workspaces-workspace {
        background-color: #f7f7f7;
    }

    .workspaces .feather {
        color: #222;
    }

    .workspaces-workspace.active {
        background-color: #ffffff;
    }

    .workspaces-workspace-name {
        color: #000;
    }

    .workspaces-workspace-name input {
        color: #000;
    }

    .App-main-left-bottom-button {
        color: #fff;
    }

    .App-main-left-bottom-button .feather {
        color: #fff;
    }

    .App-main-left-footer {
        background-color: #313131;
    }

    .App-main-left-footer-text {
        color: #fff;
        background-color: #7188c1;
    }
}