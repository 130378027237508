.App-main-right-questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a2a2a91;
    box-shadow: 0 10px 10px rgba(0,0,0,0.2);
    border-radius: 1rem;
    padding: .1em .75em;
    padding-top: .5em;
    width: 102.5%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    gap: 0.2rem;
    position: relative;
}

.App-main-right-questions-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    margin-bottom: .1rem;
    /* z-index: 99; */
}

.App-main-right-questions-header-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #eee;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* z-index: 99; */
}

.App-main-right-questions-header-title-project-name {
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: 700;
    color: #eeeeeecf;
    text-transform: uppercase;
}

.App-main-right-questions-header-title-text {
    margin-top: 0;
    opacity: 0.6;
    align-self: flex-end;
    justify-self: flex-end;
    font-size: 1.2rem;
    margin-left: 0.5rem;
}

.App-main-right-questions-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    height: 100%;
    margin-top: 0rem;
    max-height: 33em;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: 1;
}

.App-main-right-questions-buttons::-webkit-scrollbar {
    display: none;
}

.App-main-right-questions-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: 100%;
    gap: 1rem;
    background-color: transparent;
    position: relative;
    z-index: 1;
}

.App-main-right-questions-buttons-title {
    font-size: 1.3rem;
    font-weight: 500;
    color: #eee;
    width: 100%;
    text-align: left;
    white-space: pre-wrap;
    padding-bottom: 0.5rem;
}

.App-main-right-questions-buttons-line {
    width: 100%;
    height: 0.1rem;
    background-color: #eeeeee2f;
    margin-bottom: -1rem;
}

.App-main-right-questions-possible-answers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    width: 90%;
    gap: .5rem;
    margin-top: -1.5rem;
}

.App-main-right-questions-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    width: 80%;
}

.App-main-right-questions-divider-line {
    width: 100%;
    height: 0.1rem;
    background-color: #eeeeee1f;
    margin: 0;
}

.App-main-right-questions-divider-text {
    font-size: 1rem;
    font-weight: 500;
    color: #eeeeee4f;
}

.App-main-right-questions-detailed-answer-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #eeeeee5a;
    width: 100%;
    height: 2.5rem;
    text-align: left;
    white-space: pre-wrap;
    margin-bottom: -2.9rem;
    margin-top: .5rem;
}

.App-main-right-questions-detailed-answer {
    font-size: 1.4rem;
    font-weight: 500;
    color: #eee;
    width: 95%;
    background-color: #555555;
    border: none;
    border-radius: .5rem;
    padding: .5rem 1rem;
    font-family: 'Roboto', sans-serif;
    z-index: 1;
}

.App-main-right-questions-detailed-answer::placeholder {
    color: #eeeeeeaf;
}

.App-main-right-questions-detailed-answer:focus {
    outline: none;
    border-bottom: 2px solid #6584d5;
}

.questions-button {
    background: #5671b3;
    color: white;
    font-family: inherit;
    padding: 0.4em;
    padding-left: 1.2em;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 0.9em;
    border: none;
    text-align: center;
    letter-spacing: 0.04em;
    white-space: pre-wrap;
    box-shadow: 0em 1em 3em #7497F334, 0em 0.4em 0.6em #000000c0;
    min-height: 2.8em;
    width: 100%;
    transition: all .1s ease-in-out;
}

.questions-button.selected {
    background: #51ab60;
    box-shadow: 0em 1em 3em #7497F334, 0em 0.4em 0.6em #000000c0;
}

.questions-button:hover {
    cursor: pointer;
    transform: translateY(-0.1em);
    box-shadow: 0em .3em 1.2em #7497F394, 0em 0.4em 0.6em #0000005a;
}
  
.questions-button .icon {
    background: #444;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #7497F364;
    right: 0.3em;
    transition: all .5s;
}
  
.questions-button:hover .icon {
    width: calc(100% - 0.6em);
}
  
.questions-button .icon svg {
    width: 1.1em;
    transition: transform .5s;
    color: #fff;
}
  
.questions-button:hover .icon svg {
    transform: translateX(0.1em);
}
  
.questions-button:active .icon {
    transform: scale(0.95);
}

.App-main-right-questions-footer-button {
    position: absolute;
    right: .5em;
    top: 0.5em;
    background: #516aab;
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1em;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: 0em 1em 3em #7497F334, 0em 0.4em 0.6em #000000c0;
    overflow: hidden;
    height: 2.5em;
    padding-right: 4.5em;
    z-index: 999;
}
  
.App-main-right-questions-footer-button .icon {
    background: #444;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #0000005f;
    right: 0.3em;
    transition: all .5s;
}
  
.App-main-right-questions-footer-button:hover .icon {
    width: calc(100% - 0.6em);
}
  
.App-main-right-questions-footer-button .icon svg {
    width: 1.1em;
    transition: transform .5s;
    color: #fff;
}
  
.App-main-right-questions-footer-button:hover .icon svg {
    transform: translateX(0.1em);
}
  
.App-main-right-questions-footer-button:active .icon {
    transform: scale(0.95);
}

@media (max-width: 768px) {
    .App-main-right-questions-buttons {
        flex-direction: column;
        align-items: center;
        max-height: 100%;
        overflow: hidden;
        padding-top: 1em;
    }

    .App-main-right-questions-possible-answers {
        width: 100%;
    }

    .questions-button {
        padding-left: 0em;
    }
}

@media (prefers-color-scheme: light) {
    .App-main-right-questions {
        background-color: #313131;
    }

    .questions-button {
        background: #7188c1;
        box-shadow: 0em 1em 3em #7497F324, 0em 0.4em 0.6em #00000050;
    }

    .questions-button.selected {
        background: #60bb70;
        box-shadow: 0em 1em 3em #7497F324, 0em 0.4em 0.6em #00000050;
    }

    .questions-button:hover {
        box-shadow: 0em .3em 1.2em #7497F394, 0em 0.4em 0.6em #0000005a;
    }

    .App-main-right-questions-header-title-text {
        color: #fff;
    }
    
    .App-main-right-questions-detailed-answer {
        background-color: #eeeeee;
        color: #000;
    }
    
    .App-main-right-questions-detailed-answer::placeholder {
        color: #555555af;
    }
    
    .App-main-right-questions-detailed-answer:focus {
        border-bottom: 2px solid #7188c1;
    }
    
    .App-main-right-questions-footer-button {
        background: #7188c1;
    }
    
    .App-main-right-questions-footer-button .icon {
        background: #7188c1;
        display: none;
    }
    
    .App-main-right-questions-footer-button:hover .icon {
        box-shadow: 0.1em 0.1em 0.6em 0.2em #6584d5;
    }
    
    .App-main-right-questions-footer-button:active .icon {
        box-shadow: 0.1em 0.1em 0.6em 0.2em #6584d5;
    }
    
    .App-main-right-questions-footer-button:hover {
        box-shadow: 0em .3em 1.2em #6584d5, 0em 0.4em 0.6em #0000003a;
    }
    
    .App-main-right-questions-footer-button:active {
        box-shadow: 0em .3em 1.2em #6584d5, 0em 0.4em 0.6em #0000003a;
    }
    
    .App-main-right-questions-footer-button:hover .icon svg {
        color: #6584d5;
    }
    
    .App-main-right-questions-footer-button:active .icon svg {
        color: #6584d5;
    }
    
    .App-main-right-questions-footer-button:hover {
        background: #7188c1;
        color: white;
    }
}