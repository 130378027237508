.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2a2a2a91;
    box-shadow: 0 10px 10px rgba(0,0,0,0.2);
    padding: 1em 1em;
    border-radius: 1rem;
}
  
.loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
      box-shadow: -2px -2px 2px #758ecfaf;
    }
  
    50% {
      transform: rotate(180deg);
      box-shadow: -2px -2px 2px #758ecfaf;
    }
  
    100% {
      transform: rotate(360deg);
      box-shadow: -2px -2px 2px #758ecfaf;
    }
}

@media (prefers-color-scheme: light) {
    .loader-container {
        background-color: #414141;
        box-shadow: 0 3px 5px #0000005f;
    }

    @keyframes spin {
        0% {
          transform: rotate(0deg);
          box-shadow: -2px -2px 2px #758ecfff;
        }
      
        50% {
          transform: rotate(180deg);
          box-shadow: -2px -2px 2px #758ecfff;
        }
      
        100% {
          transform: rotate(360deg);
          box-shadow: -2px -2px 2px #758ecfff;
        }
    }
}
