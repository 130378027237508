
.App {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #232323;
  position: relative;
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.App-main-left {
  width: 16%;
  height: 100vh;
  /* max-height: 100vh; */
  background-color: #232323;
  position: relative;
  z-index: 100;
}

.App-main-left.closed {
  transform: translateX(-100%);
}

.App-main-right {
  flex-grow: 1;
  width: 84%;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.App-main-right::-webkit-scrollbar {
  display: none;
}

.App-main-right.full-width {
  width: 100%;
}

.App-main-right.half-width {
  width: 50%;
}

@media (max-width: 2000px) {
  .App-main-left {
    width: 14%;
  }

  .App-main-right {
    width: 86%;
  }
}

@media (max-width: 1800px) {
  .App-main-left {
    width: 15%;
  }

  .App-main-right {
    width: 85%;
  }
}

@media (max-width: 1600px) {
  .App-main-left {
    width: 16%;
  }

  .App-main-right {
    width: 84%;
  }
}

@media (max-width: 1400px) {
  .App-main-left {
    width: 18%;
  }

  .App-main-right {
    width: 82%;
  }
}

@media (max-width: 1200px) {
  .App-main-left {
    width: 20%;
  }

  .App-main-right {
    width: 80%;
  }
}

.sidebar-open-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  color: #ffffffcf;
  padding: 0.3rem 0.4rem;
}

.sidebar-open-btn:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.App-main-right-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
}

.App-main-output {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 90%;
}

.App-main-output-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;
  margin-top: .5rem;
  padding-bottom: 5rem;
  width: 100%;
}

.App-main-output-content-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  min-width: 10em;
  max-width: 12em;
}

.App-main-output-content-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 50em;
}

.App-main-output-content-right-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 105%;
  gap: .5rem;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: 30%;
}

.upload-button {
  background-color: #51ab60;
  padding: 0.9rem 1.4rem;
  color: white;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 0.6em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  /* box-shadow: 0em .1em 3em #7497F314, 0em 0.4em 0.6em #000000c0; */
  box-shadow: 0em 0em 1em #51ab6064, 0em 0.4em 0.6em #000000fa;
  animation: fadeInUp 0.5s ease-out both;
}

.upload-button:hover {
  background-color: #51ab60cf;
}

.upload.feather {
  stroke: white;
}

@media (max-width: 1200px) {
  .App-main-output-content-right-inputs {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .upload {
    width: 100%;
  }
}

.App-main-right-header-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #ccc;
  margin-top: .5rem;
}

.App-main-right-header-title-span {
  font-size: 2.5rem;
  font-weight: 800;
  color: #eee;
  margin-left: 0.3rem;
}

.App-main-footer {
  align-self: flex-end;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0rem;
  margin-top: 1rem;
  position: sticky;
  bottom: 0;
}

.App-main-footer-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #ccc;
  opacity: 0.6;
  margin-top: -.5rem;
}

@media (max-width: 768px) {
  .App {
    min-height: 100vh;
    min-width: 100vw;
  }
  .App-main-left {
    width: 100%;
  }

  .App-main-right {
    width: 100%;
  }

  .App-main-right.half-width {
    width: 0%;
  }

  .App-main-output-content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }

  .App-main-output-content-left {
    width: 150%;
  }

  .App-main-output-content-right {
    width: 100%;
  }

  .App-main-output-content-right-inputs {
    width: 95%;
    gap: .7rem;
  }

  .sidebar-open-btn {
    top: .7rem;
    left: .7rem;
    /* z-index: 100; */
  }
}

@media (prefers-color-scheme: light) {
  .App-main-left {
    background-color: #313131;
  }

  .App-main-right {
    background-color: #f5f5f5;
  }

  .App-main-right-header-title {
    color: #333;
  }

  .App-main-right-header-title-span {
    color: #333;
  }

  .App-main-footer-text {
    color: #333;
  }

  .upload-button {
    background-color: #60bb70;
    box-shadow: 0em .1em 3em #7497F314, 0em 0.4em 0.6em #00000030;
  }

  .sidebar-open-btn {
    color: #333;
  }
}