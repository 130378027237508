.App-main-input-search {
    width: 100%;
    padding: 1rem 1rem;
    resize: none;
    background-color: #4862a3;
    border: none;
    color: #fff;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    box-shadow: 0em 0em 1em #7497F364, 0em 0.4em 0.6em #000000fa;
    overflow: auto;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
    overflow: auto;
    font-family: 'Roboto', sans-serif;
    animation: fadeInUp 0.5s ease-out both;
}

.App-main-input-search.loading {
    cursor: wait;
    background-color: #5875BFff;
}

.App-main-input-search:disabled {
    color: #ccc;
}

.App-main-input-search:focus {
    outline: none;
}
  
.App-main-input-search::placeholder {
    color: #eee;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (prefers-color-scheme: light) {
    .App-main-input-search {
        background-color: #607ac2; 
        box-shadow: 0em 1em 3em #7497F334, 0em 0.4em 0.6em #0000003a;
    }
}